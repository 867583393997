const Indicator = (props: any) => {
    let classes = '';
    if(props.source === 'home/snippets/image(1).jpg'){
        classes = 'active';
    }

    return (
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={props.slideNr-1} className={classes} aria-current="true" aria-label={props.ariaLabel}>
            <img src={require(`../../assets/images/${props.source}`).default} className="d-block" alt="..." />
        </button>
    )
}

Indicator.defaultProps = {
    source: 'not-found.jpg',
    slideNr: '0',
    ariaLabel: 'Slide 1'
}

export default Indicator;