import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';

const disableNav = (element: HTMLElement) => {
  element.classList.add('hidden');
  element.ariaHidden = 'true';
  for (const child of [].slice.call(element.children)) {
    child.setAttribute('tabindex', '-1');
  }
}

const enableNav = (element: HTMLElement) => {
  element.classList.remove('hidden');
  element.ariaHidden = 'false';
  for (const child of [].slice.call(element.children)) {
    child.removeAttribute('tabindex');
  }
}

export const SilHeader = () => {
  const navItem: React.Ref<HTMLDivElement> = useRef();
  const location = useLocation();

  const handleClick = () => {
    const element = navItem.current;
    element.classList.contains('hidden') ?
      enableNav(element) :
      disableNav(element);
  }

  

  return (
    <>
      <div className="sil-header-title">
        <h1>Silvia Dubois</h1>
      </div>
      <div className="sil-header-nav">
          <nav className="sil-header-main-nav">
              <ul className="sil-nav-container">
                <li className="sil-nav-item">
                  <Link to="/" className={ location.pathname=='/' ? "active" : "" }>Home</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/nature" className={ location.pathname=='/nature' ? "active" : "" }>Nature</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/studio" className={ location.pathname=='/studio' ? "active" : "" }>Studio</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/fragments" className={ location.pathname=='/fragments' ? "active" : "" }>Fragments</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/printmaking" className={ location.pathname=='/printmaking' ? "active" : "" }>Printmaking</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/mailart" className={ location.pathname=='/mailart' ? "active" : "" }>Mail Art</Link>
                </li>
                <li className="sil-nav-item">
                  <Link to="/bio" className={ location.pathname=='/bio' ? "active" : "" }>Bio</Link>
                </li>
              </ul>
          </nav>
      </div>
      <div className="sil-mobile-header">
        <div className="black-bar-top"></div>
        <div className="sil-mobile-header-nav sil-flex">
          <div className="sil-mobile-header-nav-container__left" />
          <div className="sil-mobile-header-nav-container__right">
            <div className="sil-flex-reverse">
              <div className="sil-flex-item__right">
                <button className="sil-mobile-header-toggle" onClick={handleClick}>
                  <span className="sil-mobile-header-nav-icon"></span>
                </button>
              </div>
            </div>
              <nav ref={navItem} id="mobile-nav-menu" className="sil-mobile-nav hidden"
                aria-hidden="true">
                <ul className="sil-mobile-nav-container">
                  <li className="sil-mobile-nav-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/nature">Nature</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/studio">Studio</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/fragments">Fragments</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/printmaking">Printmaking</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/mailart">Mail Art</Link>
                  </li>
                  <li className="sil-mobile-nav-item">
                    <Link to="/bio">Bio</Link>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </>
  )}