import styled from 'styled-components';

export const ContainerStyle = styled.div`
  display: block;
  line-height: 24px;
  padding: 25px 12px;
  color: rgb(70, 70, 70);
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 71%, rgba(250,250,250,1) 95%, rgba(213,213,213,1) 100%);
  
  .sil-spacing {
    padding: 5px;
    display: block;
    max-height: 40px;
    min-height: 40px;
  }

  .sil-main-container {
    max-width: 100vw;
  }

  .carousel-control-prev-icon {
    background-image: ;
  }
`;