export const SilFooter = () => (
  <footer>
    <aside className="sil-footer-contact-aside">
      <div className="sil-footer-contact-info">
        <div className="sil-footer-left">
          <div className="sil-footer-left-header">
            <h2>CONTACT</h2>
          </div>
          <div className="sil-footer-left-info">
            <div>
              <p>E-mail: sil.dubois@gmail.com</p>
            </div>
            <div>
              <p>Gsm 0032 476 458 902</p>
            </div>
          </div>
        </div>
        <div className="sil-footer-right">
          <div>
            <a href="https://www.instagram.com/silvia_dubois/"className="fa fa-instagram"></a>
            <span className="visually-hidden">Instagram</span>
          </div>
          <div className="sil-shutterstock">
            <a href="https://www.instagram.com/silvia_dubois/"className="sil-shutterstock-link"></a>
            <span className="visually-hidden">shutterStock</span>
          </div>
        </div>
      </div>
    </aside>
    <aside className="sil-copyright">
      <div>
        Copyright © 2018 | SILVIA DUBOIS - PHOTOGRAPHY | Phone: +32 (0) 476 458 902
      </div>
    </aside>
  </footer>
)