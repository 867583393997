import Item from "./item";
import { useState } from "react";
import Indicator from "./indicator";

const Carousel = () => {
    const [fileNames, _setFileNames] = useState([
        {id: 1, source: 'image(1).jpg'}, 
        {id: 2, source: 'image(2).jpg'}, 
        {id: 3, source: 'image(3).jpg'}, 
        {id: 4, source: 'image(4).jpg'}, 
        {id: 5, source: 'image(5).jpg'}, 
        {id: 6, source: 'image(6).jpg'}, 
        {id: 7, source: 'image(7).jpg'}, 
        {id: 8, source: 'image(8).jpg'}, 
        {id: 9, source: 'image(9).jpg'}, 
        {id: 10, source: 'image(10).jpg'}, 
        {id: 11, source: 'image(11).jpg'}, 
        {id: 12, source: 'image(12).jpg'}, 
        {id: 13, source: 'image(13).jpg'}, 
        {id: 14, source: 'image(14).jpg'}, 
        {id: 15, source: 'image(15).jpg'}, 
        {id: 16, source: 'image(16).jpg'}, 
        {id: 17, source: 'image(17).jpg'}, 
        {id: 18, source: 'image(18).jpg'}, 
        {id: 19, source: 'image(19).jpg'}, 
        {id: 20, source: 'image.jpg'}
    ])

  return (
  	<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  	  <div className="carousel-inner" role="listbox" >
  	    {fileNames.map((fileName) => (
  	      <Item key={fileName.id} source={`home/${fileName.source}`} />
  	    ))}

				<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
  	    	<span id="sil-carousel-button-previous" className="fa fa-chevron-left" aria-hidden="true"></span>
  	    	<span className="visually-hidden">Previous</span>
  	  	</button>
  	  	<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
  	    	<span id="sil-carousel-button-next" className="fa fa-chevron-right" aria-hidden="true"></span>
  	    	<span className="visually-hidden">Next</span>
  	  	</button>
  	  </div>
			<div className="carousel-indicators">
  	    {fileNames.map((fileName) => (
  	      <Indicator key={'ind__' + fileName.id} source={`home/snippets/${fileName.source}`} slideNr={fileName.id} ariaLabel={'Slide ' + fileName.id.toString()} />
  	    ))}
  	  </div>
  	</div>
  )
}

export default Carousel;