import { HashRouter } from 'react-router-dom';
import { SilFooter } from './SilFooter';
import { SilHeader } from './SilHeader';
import { ContainerStyle } from './styles/ContainerStyle';

interface Props {
    children: any;
}

export const Container = (props: Props) => {
  const d = 'a';
  console.log(d);
  

  return (
    <>
      <div id='sil-header-style'>
        <HashRouter>
          <SilHeader />
        </HashRouter>
      </div>
      <ContainerStyle>
        <section className="sil-main-container">
          <div className="sil-spacing"></div>
          { props.children }
          <div className="sil-spacing"></div>
        </section>
      </ContainerStyle>
      <div id="sil-footer-style">
        <SilFooter />
      </div>
    </>
    )
}