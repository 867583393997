const Item = (props: any) => {
    let classes = 'carousel-item';
    if (props.source === 'home/image(1).jpg') classes = classes + ' active';

    return (
        <div className={classes}>
            <img src={require(`../../assets/images/${props.source}`).default} className="d-block w-auto" alt="..." />
        </div>
    )
}

Item.defaultProps = {
    source: 'not-found.jpg'
}

export default Item;