import 'App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

import { Container } from './components/container/Cointainer';
import { Home } from './pages/Home';

export const App = () => {
  useEffect(() => {
    console.log('hello');
  });

  return (
    <Container>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </HashRouter>
    </Container>
  )}

/* function App() {
  return (
    <section className="sil-section-main">
      <div className="sil-section-main__inner">
        <div className="sil-content">
          <div id="content_area">
            <div className="sil-spacer"></div>
            <div className="sil-carousel">
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
*/
