import Carousel from "../components/carousel/carousel";
import { HomeStyle } from "./styles/Home";

export const Home = () => {
  const d = 'a';
  console.log(d);

  return (
    <HomeStyle>
      <div className="carousel-container">
        <Carousel />
      </div>
    </HomeStyle>
  )
}