import styled from 'styled-components';

export const HomeStyle = styled.div`
  .carousel-container {
    margin: 0 auto;
    max-width: 840px;
    width: 100%;
    box-sizing: border-box;
  }

  // .carousel {
  //  padding: 10px 5px 0px 5px;
  //  border: 5px solid #F0F0F0;
  //  background-color: #F0F0F0;
  //}

  .carousel-inner {

    .carousel-item {
      height: 50vw;
      max-height: 460px;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .carousel-indicators {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;

    button {
      height: 25px;

      &[data-bs-target] {
        border: 0;
        margin-left: 0;
        margin-right: 4px;

        &:hover {
          opacity: 0.9;
          outline: 1px solid black;
        }
      }
    }
  }

  .carousel-inner:hover {
    .fa-chevron-left,
    .fa-chevron-right {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    pointer-events: none;    
  }

  .fa-chevron-left,
  .fa-chevron-right {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    pointer-events: auto;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.9)!important;
    }
  }

  @media(max-width: 600px) {
    .carousel-indicators {
      margin-left: auto;
      margin-right: auto;
      button {
        height: 100%;
        width: 100%;

        &[data-bs-target] {
          margin-right: 1px;
        }

        img {
          max-height: 100%;
          height: auto;
        }
      }
    }
  }
`;